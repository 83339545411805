import Vue from 'vue'
import Vuex from 'vuex'
import dingConfig from './modules/dingConfig'
import digitalCircuit from './modules/digitalCircuit'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        dingConfig,
        digitalCircuit
    },
    getters
})

export default store