import request from "@/utils/request";

// 税控盘信息
export function commoditySearch(data) {
    return request({
        url: '/invoice/commodity/commoditySearch',
        method: 'post',
        data
    });
}
// 获取当前未开票号
export function obtainNoticNumber(data) {
    return request({
        url: '/invoice/obtainNoticNumber',
        method: 'post',
        data
    });
}

// 发票领购信息余量查询
export function invoiceLinggou(data) {
    return request({
        url: '/invoice/invoiceLinggou',
        method: 'post',
        data
    });
}

// 专电普开票
export function fillInInvoice(data) {
    return request({
        url: '/invoice/fillInInvoice',
        method: 'post',
        data
    });
}

// 查询开票单位信息 id 接口
export function searchKphxxById(data) {
    return request({
        url: '/invoice/billUnit/searchKphxxById',
        method: 'post',
        data
    });
}

// 模糊查询购货单位此信息
export function buyerSearchBlur(data) {
    return request({
        url: '/invoice/buyer/buyerSearchBlur',
        method: 'post',
        data
    });
}
// 发票详情查询
export function invoiceQueryDbDdetail(data) {
    return request({
        url: '/invoice/InvoiceQueryDbDdetail',
        method: 'post',
        data
    });
}
// 红字信息表申请
export function scarletLetterSq(data) {
    return request({
        url: '/invoice/scarletLetterSq',
        method: 'post',
        data
    });
}
// 发票预览
export function dzPdfOrDown(data) {
    return request({
        url: '/invoice/download/dzPdfOrDown',
        method: 'post',
        data
    });
}
// 获取税收编码
export function getBmxxList() {
    return request({
        url: '/invoice/commodity/getBmxxList',
        method: 'post',
    });
}
// 智能赋码
export function intelligCoding(data) {
    return request({
        url: '/invoice/commodity/intelligCoding',
        method: 'post',
        data
    });
}
// 新增商品编码
export function addInvoiceSp(data) {
    return request({
        url: '/invoice/commodity/addInvoiceSp',
        method: 'post',
        data
    });
}
// 删除商品编码
export function commodityDel(data) {
    return request({
        url: '/invoice/commodity/commodityDel',
        method: 'post',
        data
    });
}
// 税种税目
export function szsmList(data) {
    return request({
        url: '/invoice/skp/szsm',
        method: 'post',
        data
    });
}
// SPBM列表CSV
export function getBmxxListBySpmcCsv(params) {
    return request({
        url: '/invoice/commodity/getBmxxListBySpmcCsv',
        method: 'get',
        params
    });
}
// 普票下载ofd
export function downloadOFD(data) {
    return request({
        url: '/invoice/download/downloadOFD',
        method: 'post',
        data
    });
}
// 普票下载pdf
export function pdfDownload(data) {
    return request({
        url: '/invoice/download/pdfDownload',
        method: 'post',
        data,
        responseType: 'blob'
    });
}
// 全电蓝票开具接口
export function blueTicket(data) {
    return request({
        url: '/invoice/qd/blueTicket',
        method: 'post',
        data
    });
}
// 获取登录验证码接口
export function getDppToken(data) {
    return request({
        url: '/invoice/qd/getDppToken',
        method: 'post',
        data
    });
}
// 获取人脸二维码
export function getFaceImg(data) {
    return request({
        url: '/invoice/qd/getFaceImg',
        method: 'post',
        data
    });
}
// 获取人脸识别结果
export function getFaceAuthResult(data) {
    return request({
        url: '/invoice/qd/getFaceAuthResult',
        method: 'post',
        data
    });
}
// 获取版式文件
export function getInvoicePdfOfd(data) {
    return request({
        url: '/invoice/qd/getInvoicePdfOfd',
        method: 'post',
        data
    });
}