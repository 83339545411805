const getters = {
    dingConfig: state => {
        let dingConfig = {
            ...state.dingConfig
        };
        let userInfoStr = dingConfig.userInfo;
        if (typeof userInfoStr == 'string' && userInfoStr != '') {
            dingConfig.userInfo = JSON.parse(userInfoStr);
        }
        let corpInfoStr = dingConfig.corpInfo;
        if (typeof corpInfoStr == 'string' && corpInfoStr != '') {
            dingConfig.corpInfo = JSON.parse(corpInfoStr);
        }
        return dingConfig
    },
    digitalCircuit: state => state.digitalCircuit
}
export default getters