// 数电用户信息
import Cookies from 'js-cookie'

const state = {
    nsrsbh: Cookies.get('nsrsbh') || '',
    rzid: Cookies.get('rzid') || '',
}

const mutations = {
    SET_NSRSBH: (state, nsrsbh) => {
        state.nsrsbh = nsrsbh
        Cookies.set('nsrsbh', nsrsbh)
    },
    SET_RZID: (state, rzid) => {
        state.rzid = rzid
        Cookies.set('rzid', rzid)
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}