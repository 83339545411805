export default [
    // 首页
    {
        path: '/',
        component: () =>
            import ('@/views/home'),
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () =>
            import ('@/views/invoice'),
    },
    {
        path: '/red-flush',
        name: 'red-flush',
        component: () =>
            import ('@/views/red-flush'),
    },
    {
        path: '/apply-for',
        component: () =>
            import ('@/views/apply-for'),
    },
    {
        path: '/invoice-preview',
        name: 'invoice-preview',
        component: () =>
            import ('@/views/invoice-preview'),
    },
    {
        path: '/butt-joint',
        name: 'butt-joint',
        component: () =>
            import ('@/views/dingdingtest'),
    },
    {
        path: '/electronic-invoice',
        name: 'electronic-invoice',
        component: () =>
            import ('@/views/electronic-invoice'),
    },
    {
        path: '/electronic-preview',
        name: 'electronic-invoice-preview',
        component: () =>
            import ('@/views/electronic-invoice-preview'),
    }
]