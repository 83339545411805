<template>
	<el-dialog title="登录" :visible.sync="dialogVisible" width="430px" :show-close="false">
		<el-form :model="loginParams" :rules="rules" ref="ruleForm">
			<el-form-item prop="nsrsbh">
				<el-input v-model="loginParams.nsrsbh" placeholder="请输入纳税人识别号"></el-input>
			</el-form-item>
			<el-row :gutter="24">
				<el-col :span="17">
					<el-form-item prop="sms">
						<el-input v-model="loginParams.sms" placeholder="请输入验证码"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<el-button v-if="timeFlag" disabled type="text">{{ downTime }}秒后重试</el-button>
					<el-button v-else type="text" @click="sendSms">发送验证码</el-button>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="close">取 消</el-button>
			<el-button type="primary" @click="submit">登 录</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { getDppToken } from '@/apis/invoice'
export default {
	data() {
		return {
			dialogVisible: false,
			loginParams: {
				nsrsbh: '',
				sms: ''
			},
			rules: {
				nsrsbh: [
					{ required: true, message: '请输入纳税人识别号', trigger: ['blur', 'change'] }
				],
				sms: [
					{ required: true, message: '请输入验证码', trigger: ['blur', 'change'] }
				]
			},
			timeFlag: false,
			downTime: 60,
			timeInterval: null
		};
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		// 登录
		submit() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					getDppToken(this.loginParams).then(res => {
						if (res.code == 200) {
							this.$store.commit('digitalCircuit/SET_NSRSBH', this.loginParams.nsrsbh)
							this.$message.success('登录成功')
							this.close()
						}
					})
				} else {
					return false;
				}
			})
		},
		// 发送验证码
		sendSms() {
			if (this.loginParams.nsrsbh == '') {
				this.$message.error('请输入纳税人识别号')
				return
			}
			let params = {
				nsrsbh: this.loginParams.nsrsbh
			}
			getDppToken(params).then(res => {
				if (res.code == 200) {
					this.$message.success(res.msg)
					this.countdown()
				}
			})
		},
		close() {
			this.dialogVisible = false;
			this.resetForm()
		},
		resetForm() {
			if (this.$refs.ruleForm) {
				this.$refs.ruleForm.resetFields();
			}
		},
		countdown() {
			this.timeFlag = true;
			if (this.timeInterval) {
				clearInterval(this.timeInterval)
			}
			this.timeInterval = setInterval(() => {
				this.downTime -= 1;
				if (this.downTime <= 0) {
					clearInterval(this.timeInterval)
					this.timeInterval = null;
					this.timeFlag = false;
					this.downTime = 60
				}
			}, 1000);
		}
	}
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
	border-radius: 12px;
}
</style>