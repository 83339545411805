<template>
	<div id="app">
		<router-view />
		<electricity-login ref="electricityLogin" />
		<face-recognition ref="faceRecognition" />
	</div>
</template>

<script>
import electricityLogin from '@/components/ElectricityLogin';
import faceRecognition from '@/components/FaceRecognition';
export default {
	name: 'app',
	components: {
		electricityLogin,
		faceRecognition
	},
	mounted() {
		this.$eventBus.$on('electricity-login', (data) => {
			this.$refs.electricityLogin.open()
		})
		this.$eventBus.$on('face-recognition', (data) => {
			this.$refs.faceRecognition.open(data)
		})
	}
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
}

.el-message {
	z-index: 999999 !important;
}
</style>
