import axios from "axios";
import { Message } from 'element-ui'
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '/',
    // 超时
    timeout: 20000
});
// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在请求发送之前做一些处理
        // const token = getToken();
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        // config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    error => {
        // 发送失败
        Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data;
        if (dataAxios && (dataAxios.code == 500)) {
            let errormsg = JSON.parse(dataAxios.msg);
            Message.error(errormsg.msg || errormsg.message)
        }
        return dataAxios;
    },
    error => {
        return error;
        // return Promise.reject(error);
    }
);
// 获取钉钉token
export function getAccessToken(corpId) {
    return service({
        url: `/hfjysj-api/invoice/getCorpAccessToken?corpId=${corpId}`,
        method: 'post',
    });
}
// 获取登录信息
export function invoiceLogin(params) {
    return service({
        url: `/hfjysj-api/invoice/login?corpId=${params.corpId}&code=${params.authCode}`,
        method: 'post',
    });
}
// 获取个人信息
export function getUserInfo(params) {
    return service({
        url: `/dingding/topapi/v2/user/get?access_token=${params.access_token}`,
        method: 'post',
        data: {
            userid: params.userid
        }
    });
}
// 获取部门id列表
export function departmentListSubId(params) {
    return service({
        url: `/dingding/topapi/v2/department/listsubid?access_token=${params.access_token}`,
        method: 'post',
        data: {
            dept_id: params.dept_id
        }
    });
}
// 获取部门列表
export function departmentListSub(params) {
    return service({
        url: `/dingding/topapi/v2/department/listsub?access_token=${params.access_token}`,
        method: 'post',
        data: {
            dept_id: params.dept_id
        }
    });
}

// 获取部门用户详情
export function departmentUserList(params) {
    return service({
        url: `/dingding/topapi/v2/user/list?access_token=${params.access_token}`,
        method: 'post',
        data: {
            dept_id: params.dept_id,
            cursor: params.page,
            size: params.size
        }
    });
}
// 获取外部联系人列表
export function extcontactUserList(params) {
    return service({
        url: `/dingding/topapi/extcontact/list?access_token=${params.access_token}`,
        method: 'post',
        data: {
            offset: params.page,
            size: params.size
        }
    });
}
// 发送通知
export function sendTodoByUserId(data) {
    return service({
        url: `/hfjysj-api/invoice/ding/sendTodoByUserId`,
        method: 'post',
        data
    });
}

// 获取当前用户的表头
export function getCorpInfo(corpId) {
    return service({
        url: `/hfjysj-api/invoice/corp/getCorpInfo?corpId=${corpId}`,
        method: 'post',
    });
}
// 更新表头数据
export function updateCorpInfo(data) {
    return service({
        url: `/hfjysj-api/invoice/corp/updateCorpInfo`,
        method: 'post',
        data
    });
}

// 更新表头数据
export function sendIssueOaMessage(data) {
    return service({
        url: `/hfjysj-api/invoice/ding/sendIssueOaMessage`,
        method: 'post',
        data
    });
}