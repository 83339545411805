//1. 导入 Vue 和 VueRouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
//2. 调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(VueRouter)

//3. 创建路由的实例对象
const router = new VueRouter({
	 mode: 'history', 
	 base:'/',
	 scrollBehavior: () => ({ y: 0 }),
	 routes
})

//4. 向外共享路由的实例对象
export default router