import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import config from './config'
import * as util from './utils/util'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element)
Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.prototype.$util = util;
Vue.prototype.$eventBus = new Vue();
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')