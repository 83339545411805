<template>
	<el-dialog title="人脸识别" :visible.sync="dialogVisible" width="430px" :show-close="false">
		<h3 class="title-text">使用电子税局App扫码认证</h3>
		<div class="qr-code" @click="getQrCode">
			<div id="qrcode"></div>
			<!-- <img src="https://open.weixin.qq.com/connect/qrcode/001wS0Pe03Yi1w3J" title="点击刷新二维码" alt=""> -->
		</div>
		<el-button type="text" @click="refreshStatus" class="refresh-btn">已认证 点击刷新当前状态</el-button>
		<span slot="footer" class="dialog-footer">
			<!-- <el-button @click="close">取 消</el-button>
			<el-button type="primary" @click="submit">登 录</el-button> -->
		</span>
	</el-dialog>
</template>
<script>
import { getFaceImg, getFaceAuthResult } from '@/apis/invoice'
import { mapGetters } from 'vuex';
import QRCode from "qrcodejs2"
export default {
	data() {
		return {
			dialogVisible: false,
			refreshParams: {
				nsrsbh: '',
				rzid: ''
			}
		};
	},
	computed: {
		...mapGetters(["digitalCircuit"]),
	},
	methods: {
		open(data) {
			this.dialogVisible = true;
			if (data) {
				this.refreshParams.nsrsbh = data.nsrsbh;
				this.refreshParams.rzid = data.rzid;
				this.$nextTick(() => {
				this.createQrCode(data.ewm)
				})
			} else {
				this.refreshParams.nsrsbh = this.digitalCircuit.nsrsbh;
				this.getQrCode()
			}
		},
		getQrCode() {
			let params = {
				nsrsbh: this.digitalCircuit.nsrsbh
			}
			getFaceImg(params).then(res => {
				if (res.code == 200) {
					this.refreshParams.rzid = res.data.rzid;
					this.createQrCode(res.data.ewm)
				}
			})
		},
		createQrCode(value) {
			document.getElementById('qrcode').innerHTML = '';
			new QRCode(document.getElementById('qrcode'), {
				text: value,
				width: 260,
				height: 260,
				colorDark: "#000000",
				colorLight: "#ffffff",
				correctLevel: QRCode.CorrectLevel.H
			})
		},
		// 刷新人脸识别状态
		refreshStatus() {
			getFaceAuthResult(this.refreshParams).then(res => {
				if (res.code == 200) {
					if (res.data.slzt == '1') {
						this.$message.error('未认证,请使用电子税局App扫码认证')
					} else {
						this.$message.success('认证成功')
						this.close()
					}
				}
			})
		},
		close() {
			this.dialogVisible = false;
		},
	}
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
	border-radius: 12px;

	.el-dialog__header {
		text-align: center;
	}

	.el-radio-group {
		width: 100%;

		.el-radio-button {
			width: 50%;
		}

		.el-radio-button__inner {
			width: 100%;
		}
	}

	.el-dialog__body {
		padding-top: 10px;
	}
}

.title-text {
	text-align: center;
}

.qr-code {
	width: 260px;
	height: 260px;
	margin: 0 auto;
	margin-top: 10px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}
}

.refresh-btn {
	margin: 0 auto;
	display: block;
}
</style>