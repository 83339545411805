import Cookies from 'js-cookie'
import dd from 'dingtalk-jsapi';
import { getAccessToken, invoiceLogin, getUserInfo, getCorpInfo } from "@/utils/dingding";

const state = {
    corpId: Cookies.get('corpId') || '',
    authCode: Cookies.get('authCode') || '',
    accessToken: Cookies.get('accessToken') || '',
    userInfo: Cookies.get('userInfo') || '',
    corpInfo: Cookies.get('corpInfo') || '',
}

const mutations = {
    SET_CORPID: (state, corpId) => {
        state.corpId = corpId
        Cookies.set('corpId', corpId)
    },
    SET_AUTHCODE: (state, authCode) => {
        state.authCode = authCode
        Cookies.set('authCode', authCode)
    },
    SET_ACCESSTOKEN: (state, accessToken) => {
        state.accessToken = accessToken
        Cookies.set('accessToken', accessToken)
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
        Cookies.set('userInfo', JSON.stringify(userInfo))
    },
    SET_CORPINFO: (state, corpInfo) => {
        state.corpInfo = corpInfo
        Cookies.set('corpInfo', JSON.stringify(corpInfo))
    },
}

const actions = {
    initConfig({ dispatch, commit }, corpId) {
        commit('SET_CORPID', corpId)
        dd.ready(function() {
            dd.runtime.permission.requestAuthCode({
                corpId: corpId, // 企业 ID
                onSuccess: function(result) {
                    // 获取授权码成功后的回调
                    commit('SET_AUTHCODE', result.code)
                    dispatch('getAccessToken');
                    dispatch('getTitleInfo');
                    // that.getUserInfo()
                },
                onFail: function(err) {
                    // 获取授权码失败后的回调
                    console.log('requestAuthCode error:', err);
                }
            });
        });
    },
    getAccessToken({ dispatch, commit, state }) {
        getAccessToken(state.corpId).then(res => {
            if (res.code == 0) {
                commit('SET_ACCESSTOKEN', res.data.access_token)
                dispatch('getUserInfo');
            }
        })
    },
    async getUserInfo({ commit, state }) {
        let loginParams = {
            corpId: state.corpId,
            authCode: state.authCode
        }
        let loginResult = await invoiceLogin(loginParams)
        if (loginResult.code == 0) {
            let userParams = {
                access_token: state.accessToken,
                userid: loginResult.data.userId
            }
            let userResult = await getUserInfo(userParams)
            if (userResult.errcode == 0) {
                commit('SET_USERINFO', userResult.result)
            }
        }
    },
    getTitleInfo({ commit, state }) {
        getCorpInfo(state.corpId).then(res => {
            if (res.code == 0) {
                commit('SET_CORPINFO', res.data)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}