import axios from "axios";
// import {
// 	getToken
// } from '@/utils/auth';
// import store from '@/store'
import Vue from "vue";
import { Message } from 'element-ui'
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '/hfjysj-api',
    // 超时
    timeout: 20000
});
// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在请求发送之前做一些处理
        // const token = getToken();
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        // config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    error => {
        // 发送失败
        console.log(error);
        Promise.reject(error);
    }
);
service.interceptors.response.use(
    response => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data;
        if (dataAxios && (dataAxios.code == 401 || dataAxios.code == 410)) {
            loginOut();
        }
        if (dataAxios && (dataAxios.code == 500)) {
            let errormsg;
            try {
                errormsg = JSON.parse(dataAxios.msg)
            } catch (error) {
                errormsg = dataAxios.msg
            }
            Message.error(errormsg.msg || errormsg.message || errormsg)
            if (errormsg.code) {
                switch (errormsg.code) {
                    case 302:
                        Vue.prototype.$eventBus.$emit('electricity-login')
                        break;
                    default:
                        break;
                }
            }
        }
        // if (dataAxios && (dataAxios.code == 201)) {
        //     Vue.prototype.$eventBus.$emit('face-recognition')
        // }
        return dataAxios;
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    loginOut();
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        return error;
        // return Promise.reject(error);
    }
);

function loginOut() {
    console.log('登出');
    // store.dispatch('LogOut').then(() => {
    // 	// location.href = '/portal-ui/login';
    // 	location.href = '/';
    // })
}
export default service;